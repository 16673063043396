
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import Methodology from './pages/Methodology/Methodology';
// import Nav from './components/Nav/Nav';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Footer from './components/Footer/Footer';
import Mission from './pages/Mission/Mission';
import Nav from './components/Nav/Nav';

function App() {

  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path='/*' element={<PageNotFound />} />
          <Route path='/' element={<HomePage />} />
          <Route path='/methodology' element={<Methodology />} />
          <Route path='/mission' element={<Mission />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
