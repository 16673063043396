import './Methodology.scss'
import hero from '../../assets/pics/book-inhand.jpg'

function Methodology() {
    return (
        <>
            <section className='container'>
                <h1 className='container-title'>
                    Progress, Connectivity, Inspiration
                </h1>
                <div className='meth-main-container'>

                    <div className='meth-main-content'>

                        <p>
                            <img className='hero-container__image' src={hero} alt='hero pic' align="right" /><br clear="left" />
                            Step into our dynamic classrooms, where the air crackles with anticipation and every exchange is a symphony of voices echoing the richness of global communication. Through CLT, we shatter the barriers of traditional language instruction, igniting a passion for learning that propels our students to unprecedented heights.
                        </p>
                        <p>At PCI, we're not just teaching English; we're crafting storytellers, diplomats, and global citizens. From immersive role-plays that transport you to distant lands to collaborative projects that inspire innovation, every lesson is an adventure waiting to unfold.</p>
                        <p>Join us at Paramount Communication Institute, where CLT isn't just a teaching methodology—it's a revolution in language education. Here, the world is your classroom, and the possibilities are limitless. Are you ready to embark on a journey that will redefine the way you see the world and your place in it?</p>
                    </div>
                    <div className='hero-container'>

                    </div>
                </div>
            </section>
        </>
    );
}
export default Methodology
