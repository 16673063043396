import './Mission.scss';
import teacher from '../../assets/pics/teacher-student.jpg'

function Mission() {
    return (
        <>
            <div className="main">
                <header className="main-header">
                    <h1 className='main-heading'>Bridging Gaps, Breaking Barriers, Building Futures</h1>
                    <div className='main-content-container'>

                        <div className='main-content'>

                            <p>
                                <img className='hero-container__image' src={teacher} alt='teacher and student pic' align="left" /><br clear="right" />
                                After years of teaching in various esteemed language schools and amassing extensive experience in ESL/EFL education, we recognized a glaring need to extend our reach to students who lacked access to quality language instruction. Determined to bridge this gap, we ventured beyond the confines of urban centers and established our institute.</p>

                            <p>Despite the considerable distance from the provincial center, we ventured forth to establish this institute, determined to bring quality language education to the rural population. In our inaugural semester, we were astounded by the overwhelming response from eager learners, with our enrollment surpassing 200 students.</p>

                            <p>Since our inception, we have remained steadfast in our mission to provide communicative classes that empower our students. Through innovative teaching methods and a dedicated faculty, we have successfully cultivated a thriving learning environment, attracting motivated individuals to our institute.</p>

                            <p>Formerly marginalized learners, who once faced limited opportunities for quality education, now find fulfillment and growth within our classrooms. We take pride in our role as catalysts for positive change, empowering our students to realize their full potential.</p>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}
export default Mission
