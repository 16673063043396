
import './Nav.scss';
import logo from '../../assets/pics/inst-black.jpg'


function Nav() {
    return (
        <>
            <div className='nav'>
                <a className='nav-logo__container' href='/'><img src={logo} className="main-logo" alt="logo" /></a>
                <a href='/'>Home</a>
                <a href='/mission'>Mission</a>
                <a href='/methodology'>Methodology</a>
            </div>
        </>
    )
}
export default Nav
