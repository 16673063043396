import './HomePage.scss'
import { useState, useEffect } from 'react';
import axios from 'axios';

function HomePage() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [heroImage, setHeroImage] = useState("");

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const isFirstNameValid = (firstName) => {
    if (typeof firstName === 'string' && firstName.trim().length >= 2 && firstName.trim().length <= 15) {
      return true;
    } else {
      alert("Please re-enter the first name. It should be between 2 and 15 characters.");
      return false;
    }
  };
  const isLastNameValid = (lastName) => {
    if (typeof lastName === 'string' && lastName.trim().length >= 2 && lastName.trim().length <= 25) {
      return true;
    } else {
      alert("Please re-enter the last name. It should be between 2 and 25 characters.");
      return false;
    }
  };
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (typeof email === 'string' && email.trim().length >= 5 && email.trim().length <= 50 && email.match(emailRegex)) {
      return true;
    } else {
      alert("Please enter a valid email address. It should be between 5 and 50 characters and in a valid email format.");
      return false;
    }
  };
  const isMessageValid = (message) => {
    if (typeof message === 'string' && message.trim().length >= 10 && message.trim().length <= 300) {
      return true;
    } else {
      alert("Please re-enter the message. It should be between 10 and 300 characters.");
      return false;
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isFirstNameValid(firstName) && isLastNameValid(lastName) && isEmailValid(email) && isMessageValid(message)) {
      try {
        await axios.post(`https://pci-server-7fdf2c7d57e7.herokuapp.com/submit-form`, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          message: message,
        });
        alert("Submitted the message Successfully");
        resetForm();
        window.location.href = "/";
      } catch (error) {
        console.error("Error Submitting the message", error.response);
        alert("Failed to submit the message. Please try again later.");
      }
    } else {
      alert("Failed to submit");
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    resetForm();
  };

  useEffect(() => {
    async function fetchHeroImage() {
      try {
        const response = await axios.get(`https://pci-server-7fdf2c7d57e7.herokuapp.com/hero-image`, { responseType: 'arraybuffer' });
        const arrayBuffer = response.data;
        const base64Image = arrayBufferToBase64(arrayBuffer);
        const imageUrl = `data:image/jpeg;base64,${base64Image}`;
        setHeroImage(imageUrl);
      } catch (error) {
        console.log('Error fetching hero image:', error);
      }
    }

    function arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    fetchHeroImage();
  }, []);


  return (
    <>
      <div className='main-container'>
        <p className='main-container__welcome'>Welcome to Paramount Communication Institute, where language learning transcends the mundane <br />and transforms into an exhilarating voyage of discovery. <br />Here, amidst the vibrant pulse of Communicative Language Teaching (CLT), students embark on an odyssey of linguistic exploration unlike any other.</p>
        <div className='hero-container'>

          <img className='hero-container-image' src={heroImage} alt='hero pic' />
        </div>
      </div>
      <form className='form' onSubmit={handleSubmit}>
        <h3>Have Questions? Ask Away.</h3>
        <div className='form-main-content'>
          <label className='first-name' htmlFor="">First Name</label>
          <input className='first-name-input' value={firstName} onChange={handleFirstName} type='text' placeholder='First Name'></input>
          <label className='last-name' htmlFor="">Last Name</label>
          <input className='last-name-input' value={lastName} onChange={handleLastName} type='text' placeholder='Last Name'></input>
          <label className='email' htmlFor="">Email</label>
          <input className='email-input' value={email} onChange={handleEmail} type='email' placeholder='Email'></input>
          <label className='message' htmlFor="">Message</label>
          <input className='message-input' value={message} onChange={handleMessage} type='text' placeholder='Please enter your message'></input>
        </div>
        <div className='form-buttons-container'>
          <button className='button submit-button' type='submit'>Submit</button>
          <button className='button' type='button' onClick={handleCancel}>Cancel</button>
        </div>
      </form>

    </>
  )
}
export default HomePage
