import './Footer.scss'
import facebook from '../../assets/icons/Icon-facebook.svg';
import instagram from '../../assets/icons/Icon-instagram.svg';
import x from '../../assets/icons/Icon-twitter.svg';
import copyright from '../../assets/icons/copyright-icon.svg'

function Footer() {
    return (
        <>
            <div className='footer-container'>
                <div className='contact-container'>
                    <h1 className='footer'>
                        Contact Us
                    </h1>
                    <p>
                        <strong>Email:  </strong>
                        <a className='email-link' href='mailto:pci.canada.english@gmail.com'>pci.canada.english@gmail.com</a>
                    </p>

                </div>
                <div className='social-container'>
                    <h1 className='footer'>
                        Social Media
                    </h1>
                    <div className='social-icons-container'>
                        <a href="https://instagram.com"><img className='instagram-pic' src={instagram} alt="instagram icon" /></a>
                        <a href="https://facebook.com"><img className='facebook-pic' src={facebook} alt="facebook icon" /></a>
                        <a href="https://x.com"><img className='x-pic' src={x} alt="x icon" /></a>
                    </div>
                </div>
            </div>
            <div className='copyright-container'>
                <img src={copyright} alt="copyright pic" />
                <p className='footer'>
                    2015 Paramount Communication Institute. All rights reserved.
                </p>
            </div>
        </>
    )
}
export default Footer
